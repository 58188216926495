export const SPEAKER_SOCIAL_LINKS_FRAGMENT = `
  fragment speakerSocialLinksFragment on Speaker {
    uid
    id
    schemaCode
    homepageUrl
    twitterUrl
    facebookUrl
    instagramUrl
    pinterestUrl
  }
`;
